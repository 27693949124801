import React from "react";
import { Link } from "react-router-dom";
import { imageUrl } from "../../baseApi";

const Categories = ({ style, data }) => {
  const returnClass = (index) => {
    if (index === 0) {
      return style.category1;
    }
    if (index === 1) {
      return style.category2;
    }
    if (index === 2) {
      return style.category3;
    }
    if (index === 3) {
      return style.category4;
    }
    if (index === 4) {
      return style.category5;
    }
  };
  return (
    <div className={style.categories}>
      {data?.map((cat, index) => {
        return (
          <Link
            to={"/shop"}
            className={`${style.category} ${returnClass(index)}`}
            style={{ backgroundImage: `url(${imageUrl + cat?.image?.url})` }}
            key={cat.id}
          >
            <div className={style.overlay}>
              <div className={style.text}>
                <p>Kategori</p>
                <h4>{cat?.name}</h4>
                <Link to="/shop">- SHOP NOW -</Link>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default Categories;
