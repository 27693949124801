import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { CiInstagram } from "react-icons/ci";
import { imageUrl } from "../../baseApi";
const Instagram = ({ style, data = [] }) => {
  return (
    <div className={style.instagram}>
      <p>instagram</p>
      <h2>
        Na ndiqni
        <a
          href="https://www.instagram.com/onbeauty.aesthetics/"
          target="_blank"
          rel="noreferrer"
        >
          @onbeauty.aesthetics
        </a>
      </h2>
      <div className={style.instagramPosts}>
        <Swiper
          className={style.instagramSwiper}
          slidesPerView={5}
          spaceBetween={0}
          loop
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          breakpoints={{
            1200: {
              slidesPerView: 5,
            },
            1024: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 3,
            },
            650: {
              slidesPerView: 3,
            },
            480: {
              slidesPerView: 2,
            },
            320: {
              slidesPerView: 1,
            },
            300: {
              slidesPerView: 1,
            },
          }}
        >
          {data?.posts?.map((post) => {
            return (
              <SwiperSlide
                className={style.instagramPost}
                style={{ backgroundImage: `url(${imageUrl}${post?.url})` }}
                key={post?.id}
              >
                <a
                  href="https://www.instagram.com/onbeauty.aesthetics/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className={style.overlay}>
                    <CiInstagram size={30} color="white" />
                  </div>
                </a>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Instagram;
