import React, { createContext, useEffect, useState } from "react";
import api from "../baseApi";
import { toast } from "react-toastify";

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [homepage, setHomepage] = useState();
  const [homeCategories, setHomeCategories] = useState();
  const [about, setAbout] = useState();
  const [contact, setContact] = useState();
  const [sale, setSale] = useState();
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState();
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [bestProducts, setBestProducts] = useState([]);
  const [pagination, setPagination] = useState();
  const [categories, setCategories] = useState([]);

  const [privacy, setPrivacy] = useState();
  const [term, setTerm] = useState();
  const [shipping, setShipping] = useState();

  useEffect(() => {
    setLoading(false);
  }, []);

  const getHomepage = async () => {
    setLoading(true);
    try {
      const homepage = await api.get("/homepage", {
        params: {
          populate: {
            Banner: {
              populate: "*",
            },
            Hashtags: {
              populate: "*",
            },
            Youtube: {
              populate: "*",
            },
            Brands: {
              populate: "*",
            },
            Instagram: {
              populate: "*",
            },
          },
        },
      });

      setHomepage(homepage.data.data);
      await getCategoriesforHomepage();
      await getBestProducts();

      setLoading(false);
    } catch (error) {
      console.log("error homepage", error);
    }
  };
  const getAbout = async () => {
    setLoading(true);
    try {
      const about = await api.get("/about", {
        params: {
          populate: {
            Banner: {
              populate: "*",
            },
            Content: {
              populate: "*",
            },

            Brands: {
              populate: "*",
            },
          },
        },
      });

      setAbout(about.data.data);

      setLoading(false);
    } catch (error) {
      console.log("error about us", error);
    }
  };

  const getCategoriesforHomepage = async () => {
    setLoading(true);
    try {
      const cat = await api.get("/categories", {
        params: {
          populate: "*",
          "pagination[limit]": 5,
        },
      });

      setHomeCategories(cat.data.data);
    } catch (error) {
      console.log("error contact", error);
    }
  };
  const getContact = async () => {
    setLoading(true);
    try {
      const contact = await api.get("/contact-page", {
        params: {
          populate: {
            Contact: {
              populate: "*",
            },
          },
        },
      });

      setContact(contact.data.data);

      setLoading(false);
    } catch (error) {
      console.log("error contact", error);
    }
  };

  const sendMessage = async (data) => {
    try {
      await api.post("/contacts", {
        data,
      });

      toast.success("Mesazhi u dërgua me sukses!");
    } catch (error) {
      console.log("error contact message", error);
    }
  };

  const registerToNewsletter = async (data) => {
    try {
      await api.post("/emails", {
        data,
      });

      toast.success("Pranuam emailin tuaj me sukses");
    } catch (error) {
      if (error?.status === 400) {
        toast.warn("Ne e kemi emailin tuaj në listën tonë!");
      } else {
        console.log("error newsletter", error);
      }
    }
  };

  const getSale = async () => {
    setLoading(true);
    try {
      const sale = await api.get("/sale", {
        params: {
          populate: "*",
        },
      });

      setSale(sale.data.data);

      setLoading(false);
    } catch (error) {
      console.log("error sale", error);
    }
  };

  const getProducts = async (
    page = 1,
    pageSize = 10,
    categoryId,
    sort,

    discount,
    top,
    search
  ) => {
    setLoading(true);
    try {
      const products = await api.get("/products", {
        params: {
          populate: "*",
          "pagination[page]": page,
          "pagination[pageSize]": pageSize,
          "filters[subcategory][documentId][$eq]": categoryId,
          "sort[0]": sort,

          "filters[discount][$gt]": discount ? 0 : null,
          "filters[topProduct][$eq]": top ? true : null,
          "filters[Title][$containsi]": search !== "" ? search : null,
        },
      });
      setProducts(products.data.data.sort(() => Math.random() - 0.5));
      setPagination(products.data.meta.pagination);

      setLoading(false);
    } catch (error) {
      setProducts([]);
      setPagination();

      console.log("error products", error);
    }
  };

  const getProduct = async (id) => {
    setLoading(true);
    try {
      const products = await api.get(`/products/${id}`, {
        params: {
          populate: "*",
        },
      });
      setProduct(products.data.data);

      setLoading(false);
    } catch (error) {
      setProduct();

      console.log("error products", error);
    }
  };
  const getRelatedPorducts = async (productId, subcategoryId) => {
    setLoading(true);
    try {
      const products = await api.get(`/products`, {
        params: {
          populate: "*",
          "pagination[page]": 1,
          "pagination[pageSize]": 7,
          "filters[subcategory][documentId][$eq]": subcategoryId,
          "filters[id][$ne]": productId,
        },
      });
      setRelatedProducts(products.data.data);

      setLoading(false);
    } catch (error) {
      setRelatedProducts([]);

      console.log("error products", error);
    }
  };

  const getBestProducts = async () => {
    setLoading(true);
    try {
      const products = await api.get(`/products`, {
        params: {
          populate: "*",
          "pagination[page]": 1,
          "pagination[pageSize]": 7,
          "filters[topProduct][$eq]": true,
        },
      });
      setBestProducts(products.data.data);
    } catch (error) {
      setBestProducts([]);

      console.log("error products", error);
    }
  };

  const getCategories = async () => {
    setLoading(true);
    try {
      const products = await api.get("/categories", {
        params: {
          populate: "*",
        },
      });
      setCategories(products.data.data);

      setLoading(false);
    } catch (error) {
      setCategories([]);

      console.log("error product", error);
    }
  };

  const getPrivacy = async () => {
    setLoading(true);
    try {
      const privacy = await api.get("/privacy", {
        params: {
          populate: "*",
        },
      });

      setPrivacy(privacy.data.data.Content);

      setLoading(false);
    } catch (error) {
      setPrivacy();

      console.log("error product", error);
    }
  };
  const getTerm = async () => {
    setLoading(true);
    try {
      const privacy = await api.get("/term", {
        params: {
          populate: "*",
        },
      });

      setTerm(privacy.data.data.Content);

      setLoading(false);
    } catch (error) {
      setTerm();
      console.log("error product", error);
    }
  };

  const getShipping = async () => {
    setLoading(true);
    try {
      const privacy = await api.get("/shipping", {
        params: {
          populate: "*",
        },
      });

      setShipping(privacy.data.data.Content);

      setLoading(false);
    } catch (error) {
      setShipping();
      console.log("error product", error);
    }
  };

  const makeOrder = async (data) => {
    try {
      await api.post("/orders", data);
    } catch (error) {
      console.log("error making order", error);
    }
  };

  const value = {
    loading,
    homepage,
    getHomepage,
    getSale,
    sale,
    about,
    getAbout,
    contact,
    getContact,
    sendMessage,
    registerToNewsletter,
    homeCategories,
    getProducts,
    products,
    pagination,
    getCategories,
    categories,
    product,
    getProduct,
    relatedProducts,
    getRelatedPorducts,
    makeOrder,
    bestProducts,
    getPrivacy,
    privacy,
    getTerm,
    term,
    getShipping,
    shipping,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalProvider };
